import CaseStudyBlade from "components/common/CaseStudyBlade";
import FadeInSlideShow from "components/common/FadeInSlideShow.js";
import Features from "components/common/Features";
import HeroLayout2 from "components/common/hero/HeroLayout2";
import IndustryStripeSmall from "components/common/IndustryStripeSmall";
import InfoSlider from "components/common/InfoSlider";
import TrustedPartnerSection from "components/common/TrustedPartnerSection";
import { CTA_BUTTON_COPY } from "components/data/landing-page-data";
import GlobalLayout from "components/page/GlobalLayout";
import {
  FlexEndColumn,
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

export default function CamerasLandingPage() {
  const renderContent = data => {
    const pageData = {
      header: {
        title: "#1 Cloud-based Security Cameras for Your Business",
        paragraph:
          "Easily manage your company's physical security with Rhombus. Effortlessly access footage, share clips, and leverage edge-based AI analytics. ",
        formId: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        image: data.headerImage,
        mobileImage: data.mobileHeaderImage,
        theme: "light",
      },
      featuredLogos: {
        theme: "dark",
        logos: [
          {
            img: data.logo1,
            width: "130px",
            alt: "Hurley",
          },
          {
            img: data.logo2,
            width: "177px",
            alt: "Aramark",
          },
          {
            img: data.logo3,
            width: "115px",
            alt: "Stryker",
          },
          {
            img: data.logo4,
            width: "186px ",
            alt: "Los Angeles Film School",
          },
          {
            img: data.logo5,
            width: "149px",
            alt: "Goodwill",
          },
          {
            img: data.logo6,
            width: "137px",
            alt: "Clark Construction",
          },
        ],
      },
      infoSlider: {
        title: "The Benefits of Rhombus",
        list: [
          {
            title:
              "Stay in the know with real-time alerts for faces, vehicles, and more",
            tabTitle: "Proactive Alerts",

            description:
              "Save time, accelerate investigations, and receive real-time intelligent alerts with Rhombus AI Analytics. Through machine-learning, the system automatically learns what ‘normal’ looks like in your spaces and becomes smarter and more effective.",
            image: data.benefit3,
          },
          {
            tabTitle: "Easy Investigations",
            title:
              "Save time with multi-camera investigations and smart search.",
            description:
              "No more manual scrubbing. Camera and sensor data are layered together for fast investigations. Quickly search past footage, search for events, and securely share clips via email, text, or URL all from the Rhombus timeline.",
            image: data.benefit4,
          },
          {
            tabTitle: "Seamless Deployment",
            title: "Register plug-and-play devices in as little as 5 minutes.",
            description:
              "Complex NVR/DVRs are a thing of the past. Rhombus’ modern architecture ensures fast and easy deployment from anywhere in the world. With simplified hardware infrastructure, installation is seamless with a single cable connection via PoE, eliminating the need for manual updates.",
            image: data.benefit2,
          },
          {
            tabTitle: "Infinite Scalability ",
            title: "Enjoy an all-in-one solution that grows with your needs.",
            description:
              "With a broad suite of products that is constantly growing, Rhombus provides a true single-pane-of-glass experience. Remotely manage access control, security cameras, alarms, sensors, and integrations from a centralized platform to improve visibility and operations at scale.",
            image: data.benefit1,
          },
        ],
      },
      features: [
        {
          icon: data.icon1,
          iconAlt: "Enterprise-Grade Security",
          title: "Enterprise-Grade Security",
          p:
            "Featuring enterprise-grade encryption and regular third-party security audits, Rhombus puts your security first.",
        },
        {
          icon: data.icon2,
          iconAlt: "Resource-Efficient",
          title: "Resource-Efficient",
          p:
            "Rhombus uses 50% less bandwidth and provides 10x better latency compared to other cloud vendors.",
        },
        {
          icon: data.icon3,
          iconAlt: "Backed by a 10-Year Warranty",
          title: "Backed by a 10-Year Warranty",
          p:
            "All cameras use enterprise-grade components and include 10-year warranties for complete peace-of-mind.",
        },
        {
          icon: data.icon4,
          iconAlt: "Automatic Updates",
          title: "Automatic Updates",
          p:
            "Stay ahead of threats and protect your organization with the best modern security technology has to offer.",
        },
      ],
      caseStudyBlade: {
        image: data.bladeImage,
        logo: data.caseStudyLogo,
        author: {
          avatar: data.avatar,
          name: "Brad Minsley ",
          role: "Managing Director at 10 Federal",
          review:
            "Rhombus has allowed us to remotely view any property, be alerted to specific activities, better serve our customers, actively control building access, and more.",
        },
      },
    };

    return (
      <GlobalLayout color="var(--gradient-light)" landingPage>
        <Helmet>
          <title>Rhombus Cameras</title>
          <meta
            name="description"
            content="Discover the power of cloud-managed security cameras."
          />
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <main>
          <HeroLayout2
            data={pageData.header}
            theme={pageData.header.theme}
            formTitle={CTA_BUTTON_COPY}
            buttonText={CTA_BUTTON_COPY}
          />

          <IndustryStripeSmall
            logos={pageData.featuredLogos.logos}
            theme={pageData.featuredLogos.theme}
          />
          <SectionContainer>
            <SectionInner>
              <SectionCol>
                <FadeInSlideShow
                  images={[
                    data.imageFader1,
                    data.imageFader2,
                    data.imageFader3,
                  ]}
                />
              </SectionCol>
              <FlexEndColumn>
                <TextContainer width="550px">
                  <FancyTitleMed style={{ textAlign: "left" }}>
                    Protect Your Business with Rhombus Smart Security Cameras
                  </FancyTitleMed>
                  <MainParagraph>
                    Rhombus suite of dome, fisheye, and bullet cameras deliver
                    exceptional performance and reliability for businesses of
                    all sizes. Capture, detect, and manage security events from
                    a single pane of glass.
                  </MainParagraph>
                </TextContainer>
              </FlexEndColumn>
            </SectionInner>
          </SectionContainer>
          <InfoSlider
            data={pageData.infoSlider.list}
            title={pageData.infoSlider.title}
            color="var(--nuetral-100)"
            button
          />
          <Features
            data={pageData.features}
            title="What Sets Rhombus Apart"
            color="var(--white)"
          />
          <TrustedPartnerSection button={false} color="var(--nuetral-100)" />
          <CaseStudyBlade data={pageData.caseStudyBlade} />
        </main>
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: { eq: "components/demo/img/free-trial-header-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      mobileHeaderImage: file(
        relativePath: { eq: "components/demo/img/free-trial-header-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }

      logo1: file(
        relativePath: { eq: "components/common/logos/hurley-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: { eq: "components/common/logos/aramark-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: { eq: "components/common/logos/stryker-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: { eq: "components/common/logos/lafs-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/common/logos/goodwill-white.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/common/logos/clark-construction-white.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      benefit1: file(
        relativePath: { eq: "components/demo/img/infinite-scalability-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: { eq: "components/demo/img/seamless-deployment-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: { eq: "components/demo/img/proactive-alerts-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: { eq: "components/demo/img/easy-investigations-min.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/security.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: {
          eq: "components/industries/img/icons/certification.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/updates.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/brad-minsley.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      caseStudyLogo: file(
        relativePath: {
          eq: "components/common/logos/10-federal-logo-large.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      bladeImage: file(
        relativePath: {
          eq: "components/common/img/case-studies/10-federal-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      imageFader1: file(
        relativePath: {
          eq: "components/landing-pages/img/r100-outdoor-1-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      imageFader2: file(
        relativePath: {
          eq: "components/landing-pages/img/r100-outdoor-2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      imageFader3: file(
        relativePath: {
          eq: "components/landing-pages/img/r100-outdoor-3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;
  return <StaticQuery query={query} render={renderContent} />;
}
